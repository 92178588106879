import { useCallback, useState } from "react";
import { Footer } from "@bay1/ui";
import { MarketingNav } from "@bay1/ui/components/MarketingNav";
import Link from "next/link";

import { formium } from "../lib/formium";

// pages/404.js
// eslint-disable-next-line react/function-component-definition
export default function Custom404(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalProperties, setModalProperties] = useState({
    isVisible: false,
    headline: "",
    message: "",
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleNewsletterFormSuccess = useCallback(async () => {
    setModalProperties({
      isVisible: true,
      headline: "Thanks for signing up",
      message: "The Highnote team will be in touch.",
    });
  }, []);

  return (
    <>
      <MarketingNav />

      <div className="bg-bone min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-medium sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl sm:text-5xl">Page not found</h1>
                <p className="mt-1 text-base">
                  We couldn’t find the page you’re looking for.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link href="/">
                  <a className="text-base font-medium link">
                    Go back home<span aria-hidden="true"> →</span>
                  </a>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* Footer */}
      <Footer
        formiumClient={formium}
        onNewsletterFormSuccess={handleNewsletterFormSuccess}
      />
    </>
  );
}
